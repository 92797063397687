import {
  faArrowLeft,
  faMagnifyingGlass,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { colorsAtom } from "../models/Colors";
import {
  deleteSearchHistory,
  fetchSearchHistory,
  getSearchTerms,
  postSearchHistory,
} from "../api";
import { sessionAtom } from "../models/Session";
import { debounce } from "lodash";
import {
  faBarcodeRead,
  faSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { slugify } from "../functions";
import { SearchTermCollection } from "../models/Search";
import { appImagesAtom } from "../models/appImages";
import { faBluetoothB } from "@fortawesome/free-brands-svg-icons";

interface SearchBarQF {
  noBorder?: boolean;
}

const SearchBarQF = (props: SearchBarQF) => {
  const [appImages] = useAtom(appImagesAtom);
  const [value, setValue] = useState<string>("");
  const [terms, setTerms] = useState<SearchTermCollection>({
    products: [],
    others: [],
  });
  const [history, setHistory] = useState<{ term: string; id: number }[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchBarOpen, setSearchBarOpen] = useState<boolean>(false);
  const [searchBarFocus, setSearchBarFocus] = useState<boolean>(false);
  const [colors] = useAtom(colorsAtom);
  const { t } = useTranslation();
  const [session] = useAtom(sessionAtom);
  const navigate = useNavigate();
  const location = useLocation();

  const debounceSearch = debounce((query: string) => {
    if(query === "" && location.pathname.includes("search-results/")) {
      navigate("/");
    } else if(query !== "") {
      navigate("/search-results/" + encodeURIComponent(query) + "/1");
    }


    // if (session && value.length > 0) {
    //   getSearchTerms(session, query)
    //     .then(setTerms)
    //     .then(() => setIsSearching(false));
    // } else if (session && searchBarOpen) {
    //   setTerms({ products: [], others: [] });
    //   setIsSearching(false);
    //
    //   fetchSearchHistory(session).then(setHistory);
    // }
  }, 750);

  useEffect(() => {
    setIsSearching(true);
    setTerms({
      products: [],
      others: [],
    });
    debounceSearch(value);

    return () => {
      debounceSearch.cancel();
    };
  }, [value, searchBarOpen]);

  useEffect(() => {
    setSearchBarOpen(false);
    setValue(
      window.location.pathname.includes("/search-results/") ? value : ""
    );
    debounceSearch.cancel();
  }, [window.location.pathname]);

  return (
    <>
      <div
        className={`fixed w-screen h-screen top-0 left-0 bg-black/50 z-30 ${
          searchBarOpen || searchBarFocus ? "hidden md:block" : "hidden"
        }`}
        onClick={() => {
          setSearchBarFocus(false);
          setSearchBarOpen(false);
        }}
      />
      <div
        className={`md:top-auto md:left-auto md:relative w-full h-fit z-30 ${
          window.innerWidth < 768 && searchBarOpen
            ? "fixed top-0 left-0 bg-white"
            : ""
        } ${
          window.location.pathname.includes("/bluetooth-scan")
            ? "cursor-not-allowed"
            : ""
        }`}
        onClick={() => {
          !searchBarOpen && setSearchBarOpen(false);
        }}
      >
        <div
          className={`static md:static top-0 left-0 md:top-auto md:left-auto bg-white w-full px-5 py-3 h-12 flex items-center gap-3 ${
            window.innerWidth < 768
              ? searchBarOpen
                ? "border-b"
                : props.noBorder
                ? ""
                : "border rounded-full"
              : "rounded-full"
          } md:border-0`}
        >
          <FontAwesomeIcon
            icon={
              window.innerWidth >= 768 || !searchBarOpen
                ? faMagnifyingGlass
                : faArrowLeft
            }
            style={{ color: colors?.primaryColor }}
            fixedWidth
            className="text-xl cursor-pointer"
            onClick={() => {
              if (window.innerWidth < 768 && searchBarOpen) {
                setSearchBarOpen(false);
                setSearchBarFocus(false);
              }
            }}
          />
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="w-full"
          >
            <input
              className={`w-full appearance-none outline-0 bg-white text-sm ${
                window.location.pathname.includes("/bluetooth-scan")
                  ? "cursor-not-allowed"
                  : ""
              }`}
              value={value}
              onChange={(event) => setValue(event.target.value)}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Escape") {
                  event.currentTarget.blur();
                  setSearchBarFocus(false);
                  setSearchBarOpen(false);
                  return;
                }

                if (event.key === "Enter") {
                  event.preventDefault();
                  event.currentTarget.blur();
                  setSearchBarFocus(false);
                  setSearchBarOpen(false);
                  navigate(`/search-results/${encodeURIComponent(value)}/1`);
                  if (session && value !== "") {
                    postSearchHistory(session, value);
                  }
                  return;
                }
              }}
              onFocus={() => {
                setSearchBarFocus(false);
                setSearchBarOpen(false);
              }}
              onBlur={() => setSearchBarFocus(false)}
              onClick={() => {
                if (window.location.pathname.includes("/bluetooth-scan")) {
                  navigate("../");
                }
              }}
              placeholder={t("common.search_placeholder")!}
            />
          </form>
          <FontAwesomeIcon
            icon={faXmark}
            style={{ color: colors?.textLightColor }}
            fixedWidth
            className={`text-xl transition-all delay-75 cursor-pointer ${
              value.length > 0 ? "scale-100" : "scale-0"
            }`}
            onClick={() => setValue("")}
          />
          <FontAwesomeIcon
            icon={faBarcodeRead}
            style={{ color: colors?.textLightColor }}
            fixedWidth
            className="text-xl cursor-pointer"
            onClick={() => {
              setSearchBarFocus(false);
              setSearchBarOpen(false);
              navigate(`/scan`);
            }}
          />
          <FontAwesomeIcon
            icon={faBluetoothB}
            style={{ color: colors?.textLightColor }}
            fixedWidth
            className="text-xl cursor-pointer"
            onClick={() => {
              setSearchBarFocus(false);
              setSearchBarOpen(false);
              navigate(`/bluetooth-scan`);
            }}
          />
        </div>
        <div
          className={`hidden md:h-12 w-full absolute ${
            searchBarOpen || searchBarFocus ? "md:block" : ""
          }`}
        />
        <div
          className={`md:absolute bg-white w-full md:translate-y-6 flex flex-col gap-3 md:max-h-[480px] overflow-auto h-screen md:h-fit pb-28 md:pb-0 ${
            searchBarOpen || searchBarFocus ? "block" : "hidden"
          }`}
        >
          {value.length > 0 ? (
            terms.others.length > 0 || terms.products.length > 0 ? (
              <div className="flex flex-col divide-y mb-16 md:mb-0">
                {terms.others.length > 0 ? (
                  <div className="flex flex-col gap-1 py-3">
                    {terms.others.map((term) => (
                      <Link
                        className="py-3 px-5 flex flex-row items-center gap-3 hover:bg-stone-100"
                        to={`/${
                          term.type === 0
                            ? "category"
                            : term.type === 1
                            ? "brand"
                            : "product"
                        }/${slugify(term.name)}/${term.id}${
                          term.type === 0 || term.type === 1 ? "/1" : ""
                        }`}
                        onClick={() => {
                          setSearchBarFocus(false);
                          setSearchBarOpen(false);
                          if (session && term.name !== "") {
                            postSearchHistory(session, term.name);
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          style={{ color: colors?.neutralColor }}
                        />
                        <p>{term.name}</p>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
                {terms.products.slice(0, 3).length > 0 ? (
                  <div className="flex flex-col gap-1 py-3">
                    {terms.products.slice(0, 3).map((term) => (
                      <Link
                        className="py-3 px-5 flex flex-row items-center gap-3 hover:bg-stone-100"
                        to={`/product/${slugify(term.name)}/${
                          term.mainProductId
                            ? `${term.mainProductId}/${term.id}`
                            : term.id
                        }`}
                        onClick={() => {
                          setSearchBarFocus(false);
                          setSearchBarOpen(false);
                          if (session && term.name !== "") {
                            postSearchHistory(session, term.name);
                          }
                        }}
                      >
                        <img
                          src={term.thumbnailUrl ?? appImages?.placeholder}
                          className="h-14 w-14 aspect-square object-contain"
                        />
                        <div className="flex flex-col">
                          <p
                            className="text-xs font-bold"
                            style={{ color: colors?.textLightColor }}
                          >
                            {term.brand ?? "Merkloos"}
                          </p>
                          <p>{term.name}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
                {terms.products.slice(3, 8).length > 0 ? (
                  <div className="flex flex-col gap-1 py-3">
                    {terms.products.slice(3, 8).map((term) => (
                      <Link
                        className="py-3 px-5 flex flex-row items-center gap-3 hover:bg-stone-100"
                        to={`/product/${slugify(term.name)}/${
                          term.mainProductId
                            ? `${term.mainProductId}/${term.id}`
                            : term.id
                        }`}
                        onClick={() => {
                          setSearchBarFocus(false);
                          setSearchBarOpen(false);
                          if (session && term.name !== "") {
                            postSearchHistory(session, term.name);
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          style={{ color: colors?.neutralColor }}
                        />
                        <p>{term.name}</p>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
                <Link
                  className="px-5 py-5"
                  to={`/search-results/${encodeURIComponent(value)}/1`}
                  onClick={() => {
                    setSearchBarFocus(false);
                    setSearchBarOpen(false);
                    if (session && value !== "") {
                      postSearchHistory(session, value);
                    }
                  }}
                >
                  Alle resultaten voor{" "}
                  <span className="font-bold">{value}</span>
                </Link>
              </div>
            ) : isSearching ? (
              <div
                className="flex flex-row w-full py-3 px-5 items-center gap-3"
                style={{ color: colors?.textLightColor }}
              >
                <FontAwesomeIcon
                  icon={faSpinnerThird}
                  className="animate-spin duration-1000 text-lg"
                />
                <span>{t("common.searching")}</span>
              </div>
            ) : (
              <>
                <p
                  className="py-3 px-5 italic"
                  style={{ color: colors?.textLightColor }}
                >
                  {t("common.no_products_found")}
                </p>
                <Link
                  className="px-5 pb-5"
                  to={`/search-results/${encodeURIComponent(value)}/1`}
                  onClick={() => {
                    setSearchBarFocus(false);
                    setSearchBarOpen(false);
                    if (session && value !== "") {
                      postSearchHistory(session, value);
                    }
                  }}
                >
                  Alle resultaten voor{" "}
                  <span className="font-bold">{value}</span>
                </Link>
              </>
            )
          ) : (
            <div>
              <p
                className="text-sm py-3 px-5"
                style={{ color: colors?.textLightColor }}
              >
                {t("common.searched_earlier_for")}:
              </p>
              {history.filter((item) => item.term !== "").length === 0 ? (
                <p className="py-3 px-5">{t("common.no_search_history")}</p>
              ) : (
                <></>
              )}
              <div className="flex flex-col divide-y">
                {history
                  .filter((item) => item.term !== "")
                  .slice(0, 10)
                  .map(
                    (item) =>
                      item.term.length > 0 && (
                        <div className="py-3 flex flex-row items-center w-full gap-3 px-5">
                          <Link
                            className="flex flex-row items-center w-full gap-3"
                            to={`/search-results/${encodeURIComponent(
                              item.term
                            )}/1`}
                            onClick={() => {
                              setSearchBarFocus(false);
                              setSearchBarOpen(false);
                              setValue(item.term);
                              if (session && item.term !== "") {
                                postSearchHistory(session, item.term);
                              }
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faClock}
                              className="text-lg"
                              style={{ color: colors?.neutralColor }}
                            />
                            <p className="w-full line-clamp-1 flex-nowrap truncate">
                              {item.term}
                            </p>
                          </Link>
                          <FontAwesomeIcon
                            icon={faXmark}
                            className="cursor-pointer"
                            style={{ color: colors?.neutralColor }}
                            onClick={() => {
                              if (session) {
                                deleteSearchHistory(session, item.id).then(() =>
                                  fetchSearchHistory(session).then(setHistory)
                                );
                              }
                            }}
                          />
                        </div>
                      )
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchBarQF;
